import { v4 as uuidv4 } from "uuid";

export default {
    bookmarks: [
        {
            key: uuidv4(),
            title: "Bitwarden",
            url: "https://bitwarden.atomicparticles.eu",
            locked: "true",
        },
        {
            key: uuidv4(),
            title: "Nextcloud",
            url: "https://nextcloud.atomicparticles.eu",
            locked: "true",
        },
        {
            key: uuidv4(),
            title: "Roundcube",
            url: "https://roundcube.atomicparticles.eu",
            locked: "true",
        },
        {
            key: uuidv4(),
            title: "Element",
            url: "https://element.atomicparticles.eu",
            locked: "true",
        },
        {
            key: uuidv4(),
            title: "Gitea",
            url: "https://gitea.atomicparticles.eu/user/login",
            locked: "true",
        },
        {
            key: uuidv4(),
            title: "Polr",
            url: "https://polr.atomicparticles.eu",
            locked: "true",
        },
    ],
    settings: {
        yourName: "",
        searchEngine: "0",
        language: "en",
        startpageTitle: "Atomic Particles ",
        twelveHours: "false",
        secondsInClock: "false",
        dateFormat: "DMY",
    },
    unsplashApiUrl: "https://home.atomicparticles.eu/unsplash-random.php",
};

