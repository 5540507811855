import it from "./it.locale";
import en from "./en.locale";
import pt from "./pt.locale";
import fr from "./fr.locale";

export default {
	it,
	en,
	pt,
	fr,
};
